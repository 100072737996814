import React, { useRef } from "react";
import Inicio from "./Components/Inicio";
import Sobre from "./Components/Sobre";
import Beneficios from "./Components/Beneficios";
import Mercado from "./Components/Mercado";
import Dores from "./Components/Dores";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

function App() {
  const inicioRef = useRef(null);
  const sobreRef = useRef(null);  
  const mercadoRef = useRef(null);
  const doresRef = useRef(null);
  const beneficiosRef = useRef(null);

  const scrollToComponent = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      
      <Header scrollToComponent={scrollToComponent} refs={{ inicioRef, sobreRef, mercadoRef, doresRef, beneficiosRef }} />

      <div ref={inicioRef}>
        <Inicio />
      </div>

      <div ref={sobreRef}>
        <Sobre />
      </div>

      <div ref={mercadoRef}>
        <Mercado />
      </div>

      <div ref={doresRef}>
        <Dores />
      </div>

      <div ref={beneficiosRef}>
        <Beneficios />
      </div>

      <Footer scrollToComponent={scrollToComponent} refs={{ inicioRef, sobreRef, mercadoRef, doresRef, beneficiosRef }}/>
    </div>
  );
}

export default App;
