import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import Facebook from '../assets/img 2/facebook.svg';
import whatsapp from '../assets/img 2/whatsapp.png';
import instagram from '../assets/img 2/instagram.svg';
const Header = ({ scrollToComponent, refs }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="relative h-48 flex items-center justify-between p-4 md:p-8  bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3]">
      <img className="w-56 h-auto md:w-128" src={logo} alt="Logo da empresa" />

      <button
        className="md:hidden flex items-center text-black font-poppins"
        onClick={toggleMenu}
        aria-label="Menu de navegação"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>

      <nav className="hidden md:flex md:items-center md:space-x-8 text-black font-poppins text-sm md:text-lg">
        <a className="py-2 hover:text-verde" href="#" onClick={() => scrollToComponent(refs.inicioRef)} aria-label="Ir para a página inicial">INÍCIO</a>
        <a className="py-2 hover:text-verde" href="#" onClick={() => scrollToComponent(refs.sobreRef)} aria-label="Saiba o que é">O QUE É?</a>
        <a className="py-2 hover:text-verde" href="#" onClick={() => scrollToComponent(refs.mercadoRef)} aria-label="Conheça o mercado">MERCADO</a>
        <a className="py-2 hover:text-verde" href="#" onClick={() => scrollToComponent(refs.doresRef)} aria-label="Veja as dores">DORES</a>
        <a className="py-2 hover:text-verde" href="#" onClick={() => scrollToComponent(refs.beneficiosRef)} aria-label="Confira os benefícios">BENEFÍCIOS</a>
      </nav>

      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-end">
          <div className="bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3] p-8 w-3/4 max-w-xs md:max-w-sm h-full flex flex-col rounded-lg shadow-lg relative">
            <img className="w-24 h-auto mb-8 mx-auto" src={logo} alt="Logo da empresa" />
            <button
              className="absolute top-4 right-4 text-black text-2xl"
              onClick={toggleMenu}
              aria-label="Fechar menu"
            >
              &times;
            </button>
            <nav className="flex flex-col flex-1 space-y-4">
              <a className="block w-full py-4 px-6 bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3] text-verde border border-gray-300 rounded-lg shadow hover:bg-green-50 transition duration-150 ease-in-out" href="#" onClick={() => {scrollToComponent(refs.inicioRef); toggleMenu();}} aria-label="Ir para a página inicial">INÍCIO</a>
              <a className="block w-full py-4 px-6 bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3] text-verde border border-gray-300 rounded-lg shadow hover:bg-green-50 transition duration-150 ease-in-out" href="#" onClick={() => {scrollToComponent(refs.sobreRef); toggleMenu();}} aria-label="Saiba o que é">O QUE É?</a>
              <a className="block w-full py-4 px-6 bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3] text-verde border border-gray-300 rounded-lg shadow hover:bg-green-50 transition duration-150 ease-in-out" href="#" onClick={() => {scrollToComponent(refs.mercadoRef); toggleMenu();}} aria-label="Conheça o mercado">MERCADO</a>
              <a className="block w-full py-4 px-6 bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3] text-verde border border-gray-300 rounded-lg shadow hover:bg-green-50 transition duration-150 ease-in-out" href="#" onClick={() => {scrollToComponent(refs.doresRef); toggleMenu();}} aria-label="Veja as dores">DORES</a>
              <a className="block w-full py-4 px-6 bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3] text-verde border border-gray-300 rounded-lg shadow hover:bg-green-50 transition duration-150 ease-in-out" href="#" onClick={() => {scrollToComponent(refs.beneficiosRef); toggleMenu();}} aria-label="Confira os benefícios">BENEFÍCIOS</a>
              <a className="block w-full py-4 px-6 bg-verde2 text-lg text-white font-medium border text-center border-gray-300 rounded-lg shadow  uppercase" href="#" >Pré-Cadastro</a>
            </nav>


            <di className="flex items-center justify-center space-x-4 mt-8"> 
            <a target="_blank" href='https://www.facebook.com/buyfamaoficial/'> <img  src={Facebook}/> </a>  
            <a target="_blank" href='https://www.instagram.com/buyfarmaoficial?igsh=c3lvczVyMThubXZ6&utm_source=qr'> <img  src={instagram}/> </a> 
            <a target="_blank" href='https://wa.me/5521920097959'> <img  src={whatsapp}/> </a> 
            </di>
            <div className="text-center text-xs text-black mt-4">
              <p className="font-medium">Buy Farma</p> &copy; 2024 
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
