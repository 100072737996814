import React from 'react';
import celular from '../assets/images/SMARTPHONE 1.png';

const Beneficios = () => {
  const [beneficios, setBeneficios] = React.useState(true);

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1 p-4">
        <div className="w-full flex flex-col items-center justify-center p-4">
          <div className="w-full flex justify-center mb-4">
            <p className="text-center text-lg text-verde font-medium break-words">
              B E N E F Í C I O S
            </p>
          </div>
          
          <div className="w-full flex items-center justify-center md:max-w-full h-auto md:h-44">
            <p className="text-6xl font-semibold text-black text-center break-words">
              Entenda os nossos <span className="text-green-400">benefícios</span>
            </p>
          </div>
        </div>
        <div>
          <div className='flex flex-col md:flex-row p-4 align-center justify-center space-y-4 md:space-y-0 md:space-x-14'>
            <div className='w-full md:w-68 h-auto cursor-pointer text-center'>
              <a className={!beneficios ? "text-gray-400 font-semibold text-lg" : "text-black font-semibold text-xl"} onClick={() => setBeneficios(true)}>
                Para os  <a className='font-bold '> Donos de Farmácia </a>
              </a>
              {beneficios && (
                <div className='w-full h-2 bg-green-500'></div>
              )}
            </div>
            <div className='w-full md:w-68 h-auto cursor-pointer text-center'>
              <a className={beneficios ? "text-gray-400 font-semibold text-lg" : "text-black font-semibold text-xl"} onClick={() => setBeneficios(false)}>
                Para as  <a className='font-bold '> Donos de Farmácia </a>
              </a>
              {!beneficios && (
                <div className='w-full h-2 bg-green-500'></div>
              )}
            </div>
          </div>

          {beneficios ? (
            <div className='w-full h-auto flex flex-col md:flex-row p-4 items-center justify-center space-y-4 md:space-y-0 md:space-x-14'>
              <div className="w-full md:w-128 h-auto md:h-144 bg-white rounded-extra-large border-4 border-verde2 p-8">
                <div className="flex flex-col w-full h-full">
                  <div className="w-20 h-20 bg-green-500 border-4 border-white rounded-full flex items-center justify-center mb-4">
                    <p className="font-bold text-4xl text-white">01</p>
                  </div>
                  <div>
                    <p className="font-bold text-lg">Acesso</p>
                    <p className="font-bold text-lg">Simplificado</p>
                    <p className="mt-2">Um único login para acessar todos os fornecedores.</p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-128 h-auto md:h-144 bg-white rounded-extra-large border-4 border-verde2 p-8">
                <div className="flex flex-col w-full h-full">
                  <div className="w-20 h-20 bg-green-500 border-4 border-white rounded-full flex items-center justify-center mb-4">
                    <p className="font-bold text-4xl text-white">02</p>
                  </div>
                  <div>
                    <p className="font-bold text-lg">Comparação</p>
                    <p className="font-bold text-lg">Facilitada</p>
                    <p className="mt-2">Facilidade para comparar preços e condições entre diferentes distribuidores.</p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-128 h-auto md:h-144 bg-white rounded-extra-large border-4 border-verde2 p-8">
                <div className="flex flex-col w-full h-full">
                  <div className="w-20 h-20 bg-green-500 border-4 border-white rounded-full flex items-center justify-center mb-4">
                    <p className="font-bold text-4xl text-white">03</p>
                  </div>
                  <div>
                    <p className="font-bold text-lg">Eficiência nas</p>
                    <p className="font-bold text-lg">Compras</p>
                    <p className="mt-2">Processo de compra mais rápido, economizando tempo e recursos.</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='w-full h-auto flex flex-col md:flex-row p-4 items-center justify-center space-y-4 md:space-y-0 md:space-x-14'>
              <div className="w-full md:w-128 h-auto md:h-144 bg-white rounded-extra-large border-4 border-verde2 p-8">
                <div className="flex flex-col w-full h-full">
                  <div className="w-20 h-20 bg-green-500 border-4 border-white rounded-full flex items-center justify-center mb-4">
                    <p className="font-bold text-4xl text-white">01</p>
                  </div>
                  <div>
                    <p className="font-bold text-lg">Maior</p>
                    <p className="font-bold text-lg">Visibilidade</p>
                    <p className="mt-2">Produtos disponíveis para uma ampla rede de farmácias.</p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-128 h-auto md:h-144 bg-white rounded-extra-large border-4 border-verde2 p-8">
                <div className="flex flex-col w-full h-full">
                  <div className="w-20 h-20 bg-green-500 border-4 border-white rounded-full flex items-center justify-center mb-4">
                    <p className="font-bold text-4xl text-white">02</p>
                  </div>
                  <div>
                    <p className="font-bold text-lg">Facilidade</p>
                    <p className="font-bold text-lg">de Gestão</p>
                    <p className="mt-2">Publicação de produtos e atualizações em uma única plataforma.</p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-128 h-auto md:h-144 bg-white rounded-extra-large border-4 border-verde2 p-8">
                <div className="flex flex-col w-full h-full">
                  <div className="w-20 h-20 bg-green-500 border-4 border-white rounded-full flex items-center justify-center mb-4">
                    <p className="font-bold text-4xl text-white">03</p>
                  </div>
                  <div>
                    <p className="font-bold text-lg">Redução</p>
                    <p className="font-bold text-lg">de Custos</p>
                    <p className="mt-2">Economia com sistemas múltiplos e processos manuais.</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex  flex-col md:flex-row items-center justify-between ">

        <div className="text-black flex flex-col items-center md:items-start mb-8 md:mb-0 md:w-1/2 md:order-2">
          <h2 className='mb-4 text-1xlfont-light md:text:3xl text-verde text-center md:text-left'>
            F A Ç A <a className='ml-3 mr-3'> S E U </a> P R É - C A D A S T R O !
          </h2>
    
          <h3 className='mb-4 text-4xl md:text-5xl font-bold text-center md:text-left'>
            Sua <span className='text-verde2'>saúde</span>, mais acessível do que nunca!
          </h3>
          <p className='mb-8 text-base md:text-xl text-center md:text-left'>
            Comece a usar Buy Farma agora mesmo. Simplifique sua vida e cuide da sua saúde com apenas alguns cliques.
          </p>
          <a
            href="#"
            className="bg-green-500 text-white font-bold py-4 px-8 rounded-2xl text-lg hover:bg-green-600 transition-colors"
          >
            Faça seu Pré-Cadastro
          </a>
        </div>

        <div className="w-full md:w-auto flex justify-center md:justify-start md:pr-8 md:order-1">
          <img className="w-full  md:w-94" src={celular} alt="Celular" />
        </div>
      </div>
    </div>
  );
};

export default Beneficios;
