import React from 'react';
import img from "../assets/images/celular.png";

const HomePage = () => {
  return (
    <div className='font-poppins bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3]'>
      <div className="flex flex-col md:flex-row items-center justify-center">
        <div className="w-full max-w-6xl flex flex-col items-center md:items-start justify-center p-8 md:p-16 md:order-1">
          <h3 className="text-xl text-center md:text-lg text-verde mb-2 font-medium uppercase tracking-widest">
            Inovação<span className='mx-1'>no</span> setor <span className='mx-1'>de</span> distribuição <span className='mx-1'>de</span> <a className='tracking-widest'> medicamentos</a>
          </h3>

          <h1 className="text-4xl text-center font-bold mb-4 md:text-5xl md:text-left">
            Simplifique suas <span className="font-bold text-verde2">compras</span>, 
            potencialize <span className="font-bold text-verde2">resultados</span>!
          </h1>
          <p className="text-center text-base mb-6 md:text-left md:text-2xl">
            Conheça a Buy Farma, a solução definitiva para otimizar a compra e gestão de medicamentos, 
            transformando a forma como distribuidoras e farmácias interagem.
          </p>
          <div className="flex flex-col items-center space-y-4 md:items-start md:flex-row md:space-y-0 md:space-x-4">
            <a
              href="#"
              className="bg-green-500 text-white font-bold py-3 px-6 rounded-2xl text-lg hover:bg-green-600 transition-colors"
            >
              Faça seu Pré-Cadastro
            </a>
            <a
              href="#"
              className="text-verde2 font-bold py-3 px-6 rounded-2xl text-lg hover:text-white hover:bg-green-600 transition-colors"
            >
              Saiba Mais
            </a>
          </div>
        </div>

        <div className="relative flex items-center justify-center w-full md:w-84 md:order-2" style={{ minHeight: '500px' }}>
          <img className="w-67 md:w-68 h-auto object-contain" src={img} alt="Celular" />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
